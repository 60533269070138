import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Bio from "../components/bio";

export const About = () => {
  return (
    <Layout>
      <Bio />
      <Seo title={"Über Lars Kölpin-Freese"} />
      <blockquote>
        Gelernt habe ich viele Technologien, geblieben sind dessen Konzepte.
      </blockquote>
      <div>
        <h2>Skillset</h2>

        <h3>Methodiken & Vorgehen</h3>
        <table>
          <tr>
            <td>Test-Driven Design & Development</td>
          </tr>
          <tr>
            <td>Funktionale Programmierung</td>
          </tr>
          <tr>
            <td>Objektorientierte Programmierung</td>
          </tr>
          <tr>
            <td>DevOps</td>
          </tr>
          <tr>
            <td>Scrum und Agile</td>
          </tr>
          <tr>
            <td>User Interface Principles</td>
          </tr>
        </table>

        <h3>Sprachen & Runtimes</h3>
        <table>
          <tr>
            <th>Ökosysteme</th>
            <th>Sprachen</th>
          </tr>
          <tr>
            <td>Webbrowser</td>
            <td>HTML, CSS, JavaScript, TypeScript</td>
          </tr>
          <tr>
            <td>NodeJS</td>
            <td>JavaScript, TypeScript</td>
          </tr>
          <tr>
            <td>Java Virtual Machine</td>
            <td>Java, Kotlin</td>
          </tr>
          <tr>
            <td>Native</td>
            <td>Golang</td>
          </tr>
          <tr>
            <td>Datenabfragen</td>
            <td>SQL (Dialekt: MySQL, PostgreSQL, SQLite)</td>
          </tr>
        </table>
        <h3>Frameworks & Programmiertechnologien</h3>
        <table>
          <tr>
            <th>Anwendung</th>
            <th>Technologie</th>
          </tr>
          <tr>
            <td>Web</td>
            <td>jQuery</td>
          </tr>
          <tr>
            <td>Web/Mobile</td>
            <td>React</td>
          </tr>
          <tr>
            <td>Web/Mobile</td>
            <td>Angular / RxJS</td>
          </tr>
          <tr>
            <td>Web/Mobile</td>
            <td>VueJS</td>
          </tr>
          <tr>
            <td>Mobile</td>
            <td>Ionic</td>
          </tr>
          <tr>
            <td>Server</td>
            <td>Java EE</td>
          </tr>
          <tr>
            <td>Server</td>
            <td>Spring Boot</td>
          </tr>
          <tr>
            <td>Server</td>
            <td>ExpressJS</td>
          </tr>
          <tr>
            <td>Server</td>
            <td>NestJS</td>
          </tr>
        </table>

        <h3>Operations</h3>
        <table>
          <tr>
            <th>Anwendung</th>
            <th>Technologie</th>
          </tr>
          <tr>
            <td>Ausführung</td>
            <td>Linux</td>
          </tr>
          <tr>
            <td>Konfiguration</td>
            <td>Ansible</td>
          </tr>
          <tr>
            <td>Konfiguration</td>
            <td>FluxCD</td>
          </tr>
          <tr>
            <td>Virtualisierung</td>
            <td>Docker</td>
          </tr>
          <tr>
            <td>Orchestrierung</td>
            <td>Kubernetes</td>
          </tr>
          <tr>
            <td>Monitoring</td>
            <td>Prometheus</td>
          </tr>
          <tr>
            <td>Monitoring</td>
            <td>Loki</td>
          </tr>
          <tr>
            <td>Monitoring</td>
            <td>Grafana</td>
          </tr>
          <tr>
            <td>Security</td>
            <td>Wireguard</td>
          </tr>
          <tr>
            <td>Datenhaltung</td>
            <td>Postgres</td>
          </tr>
          <tr>
            <td>Datenhaltung</td>
            <td>Redis</td>
          </tr>
        </table>
      </div>
    </Layout>
  );
};
export default About;
